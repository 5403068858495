require("datatables.net");
require("datatables.net-responsive");
// require("datatables.net-fixedheader");

require("gsap/src/uncompressed/utils/Draggable");
require("../../vendor/greensock/ThrowPropsPlugin");

//images
require("../../../images/icons/icon-fb.svg");
require("../../../images/icons/icon-twitter.svg");

var Navigo = require("navigo");
var Handlebars = require("handlebars/runtime");
var results_table_template = require("../templates/results-table.handlebars");
var racer_details_template = require("../templates/racer-details.handlebars");

var CustomSelect = require("../../components/customSelect");

var initShareButtons = require("../blocks/share");

var page_scroll_position;

if (typeof CUR_RACE_ID !== "undefined") {
	var share_url =
		APP_ROOT +
		"/" +
		LOCALE +
		"/" +
		TRANSLATED_SECTION_NAME +
		"/" +
		CUR_RACE_ID +
		"/" +
		CUR_RACE_SLUG;

	var draggable_routes; // draggable element that contains route tabs

	/*
	* Racer details
	*/
	var loadRacerDetails = function(race_id, racer_id) {
		$.post(
			APP_ROOT +
				"/api/" +
				LOCALE +
				"/v1/results/" +
				race_id +
				"/racer/" +
				racer_id,
			displayRacerDetails,
			"json"
		);
	};

	var displayRacerDetails = function(racer_details) {
		racer_details.app_root = APP_ROOT;

		$(document).ready(function() {
			page_scroll_position = $(document).scrollTop();
			$(document).scrollTop(0);

			var racer_details_html = racer_details_template(racer_details);

			$(".page-content").css({ display: "none" });

			$(".content-wrapper").append(racer_details_html);

			initShareButtons($(".share-race-results"));

			$(".back-button").click(function(e) {
				e.preventDefault();
				router.navigate(router.root, true);
			});
		});
	};

	var getRouteResults = function(race_id, route_id) {
		results_loading_status = "loading_route_data";
		$.post(
			APP_ROOT +
				"/api/" +
				LOCALE +
				"/v1/results/" +
				race_id +
				"/route/" +
				route_id,
			displayRoutesResults,
			"json"
		);
	};

	var displayRoutesResults = function(race_data) {
		/*
		 * Compile results template
		 */
		race_data.APP_ROOT = APP_ROOT;

		var html = results_table_template(race_data);

		$(".race-results-content").empty();

		$(html).appendTo(".race-results-content");

		$("#standard-table tr").click(function(e) {
			router.navigate("racer/" + $(this).data("racer_id"));
		});

		var dataTable = $("#standard-table").DataTable({
			bAutoWidth: false,
			order: [[0, "asc"]],
			responsive: {
				details: false
			},
			paging: !$("#standard-table").hasClass("no-pagination"),
			fixedHeader: false,
			infoCallback: function(settings, start, end, max, total, pre) {
				return start + "-" + end + " of " + max;
			},
			dom: 't<"table-footer"ip<"dataTables_length">><"clear">',
			drawCallback: function(settings) {
				if (
					$("#standard-table")
						.DataTable()
						.page.info().recordsDisplay == 0
				) {
					$(".table-footer").hide();
				} else {
					$(".table-footer").show();
				}

				if (settings.oPreviousSearch.sSearch.length == 0) {
					// if length == 0 user il filtering but not searching

					// update rows index after filtering
					$("#standard-table")
						.DataTable()
						.column(0, { search: "applied", order: "original" })
						.nodes()
						.each(function(cell, i) {
							cell.innerHTML = i + 1;
						});
				}
			},

			initComplete: function() {
				// console.log($("#standard-table").hasClass("no-pagination"));
				// if ($("#standard-table").hasClass("no-pagination")) {
				// 	$(".table-footer").hide();
				// }
			}
		});

		/*
		* Search
		*/
		$("#results-search__input").keyup(function() {
			dataTable.search($(this).val()).draw();
		});

		/*
		* Filters
		*/
		var genderFilter = new CustomSelect("#gender-select");

		genderFilter.$sld_select.on("select_option", function() {
			dataTable
				.column(5)
				.search(genderFilter.getSelectedValue())
				.draw();
		});

		var categoryFilter = new CustomSelect("#category-select");

		categoryFilter.$sld_select.on("select_option", function() {
			dataTable
				.column(6)
				.search(categoryFilter.getSelectedValue())
				.draw();
		});

		/*
		*	Results per page menu
		*/
		var $page_length_select = $(
			'<select id="length-select" class="sld-select--no-border"></select>'
		);
		$page_length_select.append('<option value="10">10</option>');
		$page_length_select.append('<option value="25">25</option>');
		$page_length_select.append('<option value="50">50</option>');
		$page_length_select.appendTo(".dataTables_length");
		$(".dataTables_length").append(
			'<div class="select-label">rows per page</div>'
		);

		var page_length_custom_select = new CustomSelect("#length-select");

		page_length_custom_select.$sld_select.on("select_option", function() {
			dataTable.page.len(page_length_custom_select.getSelectedValue()).draw();
		});

		/*
		* append pagination commands outside table box
		*/
		$(".table-footer").appendTo(".page-content.page-content--grey");

		results_loading_status = "idle";
	};

	var toggleEnableDraggable = function() {
		if (
			$(".tabs-wrapper").width() <= $(".tabs").width() &&
			draggable_routes[0].enabled()
		) {
			draggable_routes[0].disable();
		} else if (!draggable_routes[0].enabled()) {
			draggable_routes[0].enable();
		}
	};

	$(document).ready(function() {
		initShareButtons($(".share-race-results"));

		/*
		 * Check if there are available routes
		 */
		if ($(".route-tab").length > 1) {
			/*
			 * Center and fade in select route message
			 */
			var v_centred_splat_margin_top =
				($(window).height() -
					$(".main-nav").outerHeight() -
					$(".page-header").outerHeight()) /
					2 -
				$(".select-route-splash").height() / 2;

			if (v_centred_splat_margin_top > 0)
				$(".select-route-splash").css({
					"margin-top": v_centred_splat_margin_top
				});

			$(".select-route-splash").animate({ opacity: 1 }, 300);

			/*
			 * Init Draggable Routes
			 */
			draggable_routes = Draggable.create(".tabs-wrapper__tabbed-nav", { 	type:"x",
																																					edgeResistance:0.9,
																																					throwProps:true,
																																					lockAxis:true,
																																					bounds: '.routes'
																																				});

			toggleEnableDraggable();

			$(window).resize( function() {
				toggleEnableDraggable();
			} );

			//routes tab
			var results_loading_status = "idle";

			$(".route-tab").click(function() {
				if (results_loading_status == "idle") {
					//reset page status
					$(".route-tab").removeClass("selected");

					getRouteResults(CUR_RACE_ID, $(this).data("id_route"));
					$(this).addClass("selected");
				}
			});
		} else if ($(".route-tab").length == 1) {
			$(".route-tab").addClass("selected");
			getRouteResults(CUR_RACE_ID, $(".route-tab").data("id_route"));
		}
	});

	/*
	 * Results router
	 */
	var router = new Navigo(
		(root =
			APP_ROOT +
			"/" +
			LOCALE +
			"/" +
			TRANSLATED_SECTION_NAME +
			"/" +
			CUR_RACE_ID +
			"/" +
			CUR_RACE_SLUG),
		(useHash = false)
	);

	router
		.on({
			"/racer/:id_racer": function(params) {
				loadRacerDetails(CUR_RACE_ID, params.id_racer);
			},

			"/": function() {
				$(".racer-details").remove();
				$(".page-content").css({ display: "block" });

				setTimeout(function() {
					$(document).scrollTop(page_scroll_position);
				}, 50);
			}
		})
		.resolve();
}
