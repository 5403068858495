require("sweetalert");

$(document).ready(function() {
	$("#send-message-form").on("click tap", function() {
		if (!checkForm()) {
			return;
		}
		console.log(JSON.stringify($(".form-newsletter").serializeArray()));
		var data = $(".form-newsletter").serializeArray();
		if ($(".checked-container .checked-box").hasClass("active")) {
			data.push({
				name: "newsletter",
				value: true
			});
		} else {
			data.push({
				name: "newsletter",
				value: false
			});
		}
		$.post("/api/en/v1/send-email", data, function(res) {
			console.log(res);
			swal(
				{
					title: swal_messages.confirm_title,
					text: swal_messages.confirm_subtitle,
					type: "success",
					showCancelButton: false,
					confirmButtonText: "OK",
					closeOnConfirm: true
				},
				function() {
					emptyInput();
				}
			);
		});
	});
	$(".checked-box").on("click tap", function() {
		if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			return;
		}
		$(this).removeClass("error");
		$(this).addClass("active");
	});
	$(".form-newsletter input, .form-newsletter textarea").each(function() {
		$(this).removeClass("error");
	});
});

function checkForm() {
	var email = $(".form-newsletter #email").val();
	if (!validateEmail(email)) {
		$(".form-newsletter #email").addClass("error");
	}

	var toCheck = ["name", "surname", "phone", "subject", "message"];
	var c = true;
	toCheck.forEach(function(check) {
		if ($(".form-newsletter #" + check).val() == "") {
			$(".form-newsletter #" + check).addClass("error");
			c = false;
		}
	});
	return c;
}

function emptyInput() {
	$(".form-newsletter input").each(function() {
		$(this).val("");
	});
}

function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}
