function validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

$(document).ready(function() {
	var lang = window.location.pathname.split("/")[1].toLowerCase();

	$(".newsletter-subscribe-butt").on("click tap", function() {
		var email = $("#newsletter-mail").val();
		var text = "";
		var classError = "";
		if (validateEmail(email)) {
			$.post(
				"/api/" + lang + "/v1/mail-list/subscribe",
				{
					email: email,
					lang: lang
				},
				function(data) {
					console.log(data);
					var res = JSON.parse(data);
					classError = "green";

					switch (res.status) {
						case "subscribed":
							switch (lang) {
								case "it":
									text = "Ti sei registrato correttamente alla newsletter !";
									break;
								case "en":
									text = "You have registered correctly to newsletters!";
									break;
								default:
							}
							break;
						case 400:
							switch (lang) {
								case "it":
									text = "Sei giÃ  iscritto alla newsletter!";
									break;
								case "en":
									text = email + " is already subscribed!";
									break;
								default:
							}
						default:
					}
					$(".right-footer-block .error").removeClass("red green");
					$(".right-footer-block .error").addClass(classError);
					$(".right-footer-block .error span").text(text);
				}
			);
		} else {
			//email non valida
			classError = "red";

			switch (lang) {
				case "it":
					text = "Email non valida";
					break;
				case "en":
					text = "Invalid Email";
					break;
				default:
			}
		}
		console.log(classError, text);
		$(".right-footer-block .error").removeClass("red green");
		$(".right-footer-block .error").addClass(classError);
		$(".right-footer-block .error span").text(text);
	});
});
