window.$ = window.jQuery = require("jquery");

import swal from "sweetalert";
import tippy from 'tippy.js';

swal.setDefaults({ confirmButtonColor: "" });

// images
require("../../images/icons/admin/icon-delete.svg");

$(document).ready( function() {
	console.log('test')
	tippy('[data-tippy-content]');

	$(".delete-race-btn").click(function() {
		var race_id = $(this).data("id_race");

		swal({
			title: "Eliminare la gara ?",
			text:
				"Verranno eliminati tutti i dati relativi alla gara compresi i risultati.",
			buttons: {
				delete: {
					text: "Elimina gara",
					value: true,
					visible: true,
					className: "",
					closeModal: true
				}
			},
			icon: "warning"
		}).then(function(isConfirm) {
			if (isConfirm)
				document.location =
					APP_ROOT + "/" + LOCALE + "/race-manager/race/delete/" + race_id;
		});
	});

	$(".delete-circuit-btn").click(function() {
		var circuit_id = $(this).data("id_circuit");

		swal({
			title: "Eliminare il circuito?",
			text: "Verranno eliminati tutti i dati relativi al circuito.",

			buttons: {
				cancel: {
					text: "Annulla",
					value: false,
					visible: true,
					closeModal: true
				},
				confirm: {
					text: "Elimina circuito",
					value: true,
					visible: true,
					closeModal: true
				}
			},
			icon: "warning"
		}).then(function(isConfirm) {
			if (isConfirm)
				document.location =
					APP_ROOT + "/" + LOCALE + "/race-manager/circuit/delete/" + circuit_id;
		});
	});

	$(".delete-results-butt").click(function(e) {
		e.preventDefault();
		swal({
			title: "Vuoi cancellare i risultati ?",
			text: "Per ripristinarli dovrai ricaricare il file csv",
			buttons: {
				delete: {
					text: "Elimina risultati",
					value: true,
					visible: true,
					className: "",
					closeModal: true
				}
			},
			icon: "warning"
		}).then(function(isConfirm) {
			if (isConfirm) {
				document.location = $(e.currentTarget).attr("href");
			}
		});
	});

});
