var Handlebars = require("/var/www/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						<option value=\""
    + alias4(((helper = (helper = helpers.gender_value || (depth0 != null ? depth0.gender_value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gender_value","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.gender_name || (depth0 != null ? depth0.gender_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gender_name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "					<option value=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "				<th>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.laps : stack1), depth0))
    + "<span class=\"order-icon\"></span></th>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "				<tr data-racer_id=\""
    + alias4(((helper = (helper = helpers.id_user || (depth0 != null ? depth0.id_user : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id_user","hash":{},"data":data}) : helper)))
    + "\" class=\"clickable result-row\">\n					<td class=\"gtm-result-row-cell\" data-overall-position=\""
    + alias4(((helper = (helper = helpers.position || (depth0 != null ? depth0.position : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"position","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.route_position || (depth0 != null ? depth0.route_position : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"route_position","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\" >"
    + alias4(((helper = (helper = helpers.bib || (depth0 != null ? depth0.bib : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bib","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\" >"
    + alias4(((helper = (helper = helpers.surname || (depth0 != null ? depth0.surname : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"surname","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\" >"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\" >"
    + alias4(((helper = (helper = helpers.nationality || (depth0 != null ? depth0.nationality : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nationality","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\" >"
    + alias4(((helper = (helper = helpers.gender || (depth0 != null ? depth0.gender : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gender","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].show_rounds : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "					<td class=\"gtm-result-row-cell\">"
    + alias4(((helper = (helper = helpers.gap || (depth0 != null ? depth0.gap : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gap","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\">"
    + alias4(((helper = (helper = helpers.time || (depth0 != null ? depth0.time : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\">"
    + alias4(((helper = (helper = helpers.team || (depth0 != null ? depth0.team : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"team","hash":{},"data":data}) : helper)))
    + "</td>\n					<td class=\"gtm-result-row-cell\">"
    + alias4(((helper = (helper = helpers.id_chip || (depth0 != null ? depth0.id_chip : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id_chip","hash":{},"data":data}) : helper)))
    + "</td>\n				</tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "					<td class=\"gtm-result-row-cell\">"
    + container.escapeExpression(((helper = (helper = helpers.laps || (depth0 != null ? depth0.laps : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"laps","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<div class=\"race-results white-box\">\n\n	<div class=\"table-header\">\n\n		<div class=\"table-filters\">\n\n			<div class=\"results-filter\">\n\n				<select id=\"gender-select\" class=\"sld-select--no-border\">\n					<option value=\"\">"
    + alias4(((helper = (helper = helpers.gender_translation || (depth0 != null ? depth0.gender_translation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gender_translation","hash":{},"data":data}) : helper)))
    + "</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.genders : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n\n				<select id=\"category-select\" class=\"sld-select--no-border\">\n					<option value=\"\">"
    + alias4(((helper = (helper = helpers.category_translation || (depth0 != null ? depth0.category_translation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category_translation","hash":{},"data":data}) : helper)))
    + "</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				</select>\n\n			</div>\n\n			<div class=\"results-search\">\n				<input class=\"search\" type=\"text\" id=\"results-search__input\" name=\"results-search__input\" value=\"\" placeholder=\""
    + alias4(((helper = (helper = helpers.search_translation || (depth0 != null ? depth0.search_translation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"search_translation","hash":{},"data":data}) : helper)))
    + "\" />\n			</div>\n\n		</div>\n\n	</div>\n\n	<table class=\"standard-table\" id=\"standard-table\">\n\n		<thead>\n			<tr>\n				<th data-priority=\"1\">#<span class=\"order-icon\"></span></th>\n				<th>"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.bib : stack1), depth0))
    + "<span class=\"order-icon\"></span></th>\n				<th data-priority=\"2\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.surname : stack1), depth0))
    + "<span class=\"order-icon\"></span></th>\n				<th data-priority=\"3\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.name : stack1), depth0))
    + "<span class=\"order-icon\"></span></th>\n				<th>Naz<span class=\"order-icon\"></span></th>\n				<th>S<span class=\"order-icon\"></span></th>\n				<th data-priority=\"5\">Cat<span class=\"order-icon\"></span></th>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.show_rounds : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "				<th>"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.gap : stack1), depth0))
    + "<span class=\"order-icon\"></span></th>\n				<th data-priority=\"4\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.time : stack1), depth0))
    + "<span class=\"order-icon\"></span></th>\n				<th>Team<span class=\"order-icon\"></span></th>\n				<th>"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.chip : stack1), depth0))
    + "<span class=\"order-icon\"></span></th>\n			</tr>\n		</thead>\n\n		<tbody>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.racers : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n		</tbody>\n\n	</table>\n\n</div>\n";
},"useData":true,"useDepths":true});