var Handlebars = require("/var/www/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "				<div class=\"split-times box\">\n\n					<table class=\"standard-table split-times-table\" id=\"split-times-table\">\n\n						<thead>\n							<tr>\n								<th>#<span class=\"order-icon\"></span></th>\n								<th>time<span class=\"order-icon\"></span></th>\n							</tr>\n						</thead>\n\n						<tbody>\n\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.split_times : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n						</tbody>\n\n					</table>\n\n				</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "						<tr data-racer_id=\""
    + alias4(((helper = (helper = helpers.id_split_time || (depth0 != null ? depth0.id_split_time : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id_split_time","hash":{},"data":data}) : helper)))
    + "\">\n							<td>"
    + alias4(((helper = (helper = helpers.split_time_number || (depth0 != null ? depth0.split_time_number : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"split_time_number","hash":{},"data":data}) : helper)))
    + "</td>\n							<td>"
    + alias4(((helper = (helper = helpers.time || (depth0 != null ? depth0.time : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time","hash":{},"data":data}) : helper)))
    + "</td>\n						</tr>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "<div class=\"racer-details\">\n\n	<div class=\"result-details-content\">\n\n		<div class=\"page-content page-content--white\">\n\n			<div class=\"race-results-header\">\n\n				<header class=\"page-header\">\n\n					<h2 class=\"race-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.race_data : depth0)) != null ? stack1.race_title : stack1), depth0))
    + "</h2>\n\n					<div class=\"share-race-results\">\n\n						<div id=\"fb-share\" class=\"share-btn share-fb-button\">\n							<img src=\""
    + alias2(((helper = (helper = helpers.app_root || (depth0 != null ? depth0.app_root : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"app_root","hash":{},"data":data}) : helper)))
    + "/images/icons/icon-fb.svg\" alt=\"share on facebook\" />\n						</div>\n\n						<div id=\"twtt-share\" class=\"share-btn share-twitter-button\">\n							<img src=\""
    + alias2(((helper = (helper = helpers.app_root || (depth0 != null ? depth0.app_root : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"app_root","hash":{},"data":data}) : helper)))
    + "/images/icons/icon-twitter.svg\" alt=\"share on twitter\" />\n						</div>\n\n						<div id=\"print-btn\">\n							<img src=\""
    + alias2(((helper = (helper = helpers.app_root || (depth0 != null ? depth0.app_root : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"app_root","hash":{},"data":data}) : helper)))
    + "/images/icons/printer.svg\" onclick=\"window.print()\" />\n						</div>\n\n					</div>\n\n				</header>\n\n			</div>\n\n			<div class=\"close-details-button\">\n				<span class=\"back-button\"> "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.ranking : stack1), depth0))
    + " </span> <span class=\"route-name\">"
    + alias2(((helper = (helper = helpers.route_name || (depth0 != null ? depth0.route_name : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"route_name","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n\n		<div class=\"page-content page-content--grey\">\n\n			<div class=\"col-left\">\n\n				<div class=\"box racer-data\">\n\n					<div class=\"avatar\"></div>\n\n					<span class=\"racer-initials\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_data : depth0)) != null ? stack1.initials : stack1), depth0))
    + "</span>\n\n					<div class=\"racer-header\">\n						<h4>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_data : depth0)) != null ? stack1.name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_data : depth0)) != null ? stack1.surname : stack1), depth0))
    + "</h4>\n					</div>\n\n					<ul>\n						<li class=\"racer-category\"><label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.category : stack1), depth0))
    + "</label><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_result : depth0)) != null ? stack1.category : stack1), depth0))
    + "</span></li>\n						<li class=\"racer-gender\"><label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.gender : stack1), depth0))
    + "</label><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_data : depth0)) != null ? stack1.gender : stack1), depth0))
    + "</span></li>\n						<li class=\"racer-nationality\"><label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.nationality : stack1), depth0))
    + "</label><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_data : depth0)) != null ? stack1.nationality : stack1), depth0))
    + "</span></li>\n						<li class=\"racer-bib\"><label>Bib</label><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_result : depth0)) != null ? stack1.bib : stack1), depth0))
    + "</span></li>\n						<li class=\"racer-team\"><label>Team</label><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_result : depth0)) != null ? stack1.team : stack1), depth0))
    + "</span></li>\n					</ul>\n\n				</div>\n\n			</div>\n\n			<div class=\"col-right\">\n\n				<div class=\"stats-container\">\n\n					<div class=\"big-box overall-position\">\n						<div class=\"box-content\">\n							<label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.general_position : stack1), depth0))
    + "</label>\n							<big>#"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.overall_position : stack1), depth0))
    + "</big>\n						</div>\n					</div>\n\n					<div class=\"big-box total-time\">\n						<div class=\"box-content\">\n							<label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.total_time : stack1), depth0))
    + "</label>\n							<big>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.total_time : stack1), depth0))
    + "</big>\n						</div>\n					</div>\n\n					<div class=\"big-box gap\">\n						<div class=\"box-content\">\n							<label>Gap</label>\n							<big>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.route_gap : stack1), depth0))
    + "</big>\n						</div>\n					</div>\n\n					<div class=\"big-box category-position\">\n						<div class=\"box-content\">\n							<label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.category_position : stack1), depth0))
    + "</label>\n							<big>#"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.category_position : stack1), depth0))
    + "</big>\n						</div>\n					</div>\n\n					<div class=\"small-box gender-position\">\n						<div class=\"box-content\">\n							<div class=\"box-content\">\n								<label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.gender_position : stack1), depth0))
    + "</label>\n								<big>#"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.gender_position : stack1), depth0))
    + "</big>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"small-box team-position\">\n						<div class=\"box-content\">\n							<label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.team_position : stack1), depth0))
    + "</label>\n							<big>#"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.team_position : stack1), depth0))
    + "</big>\n						</div>\n					</div>\n\n					<div class=\"small-box media\">\n						<div class=\"box-content\">\n							<label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translations : depth0)) != null ? stack1.average_speed : stack1), depth0))
    + "</label>\n							<big>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.media : stack1), depth0))
    + "</big>\n						</div>\n					</div>\n\n				</div>\n\n				<br style=\"clear:both;\" />\n\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.split_times : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n				<div class=\"secondary-stats box\">\n\n					<table class=\"standard-table secondary-stats-table\" id=\"split-times-table\">\n\n						<thead>\n							<tr>\n								<th colspan=\"2\">Altri Risultati</th>\n							</tr>\n						</thead>\n\n						<tbody>\n							<tr>\n								<td><label>Real Time</label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_result : depth0)) != null ? stack1.race_start : stack1), depth0))
    + "</td>\n								<td><label>Gun Start</label>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.racer_result : depth0)) != null ? stack1.gun_start : stack1), depth0))
    + "</td>\n							</tr>\n						</tbody>\n\n					</table>\n\n				</div>\n\n			</div>\n\n			<br style=\"clear:both;\" />\n\n		</div>\n\n	</div>\n\n</div>\n";
},"useData":true});